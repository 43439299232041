













































































import {Component, Vue} from 'vue-property-decorator'

import ChuFangApi from '@/api/chufang'

@Component({})
export default class ChuFang extends Vue {

  private api = new ChuFangApi()

  private dataForm = {
    sports: undefined,
    heart: undefined,
    diabetes: undefined,
    joint: undefined,
    pregnancy: undefined,
    chronic: undefined,
    plan: undefined
  }

  private frequencies = new Array()

  private async activated() {
    await this.getFrequencies()
    await this.getLast()
  }

  private deactivated() {
    this.frequencies = []
  }

  private async getFrequencies() {
    const result = await this.api.getAllFrequency()
    this.frequencies = result.items
  }

  private async getLast() {
    const result = await this.api.getLastAssessment(false)
    if (result) {
      this.dataForm = Object.assign({}, result)
    }
  }

  private async handleNext() {
    if (
      this.dataForm.heart === undefined ||
      this.dataForm.diabetes === undefined ||
      this.dataForm.joint === undefined ||
      this.dataForm.pregnancy === undefined ||
      this.dataForm.chronic === undefined ||
      this.dataForm.plan === undefined ||
      this.dataForm.sports === undefined
    ) {
      this.$notify({type: 'warning', message: '请选择有或者无'})
      return false
    }

    const result = await this.api.addMemberShipAssessment(this.dataForm)

    await this.$router.replace(`/personal-tailor/basic-info/${result.id}`)
  }
}
